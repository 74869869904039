%all-print-rules {
    @extend %avoid-breaks;
    @extend %image-size;
    @extend %hide-elems;
}

%avoid-breaks {

    p,
    a,
    table,
    img,
    canvas,
    li,
    blockquote,
    code,
    pre {
        page-break-inside: avoid;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-inside: avoid;
        page-break-after: avoid;
    }
}

%image-size {
    img {
        @media print {
            max-width: 80%;
        }
    }
}

%hide-elems {

    iframe,
    video,
    figcaption {
        @media print {
            display: none;
        }
    }
}