@import 'src/styles/variables/layout';
@import 'src/styles/base/typography';
@import 'src/styles/base/text';
@import 'src/styles/base/print';
@import 'src/styles/layout/container';

@import 'src/styles/paragraphs/text';
@import 'src/styles/paragraphs/text-and-image';
@import 'src/styles/paragraphs/checklist';

@import '@headpower/layout/assets/layout';
@import '@headpower/layout/assets/hpo-component-themes';
//@import '@headpower/layout/assets/app-component-themes';
@import 'ngx-lightbox/lightbox.css';

.mat-icon {
    color: var(--color-text-gray);
}

a>.mat-icon,
.toolbar .mat-icon {
    color: inherit;
}

@media print {
    body {
        background-color: white !important;
    }

    .mat-drawer-container {
        background-color: white !important;

        >.mat-drawer-content {
            >.page-grid {
                gap: 0 !important;
                margin: 0 !important;
            }
        }
    }
}