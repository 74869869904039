// Text paragraph Drupal styles

.text-align-left,
.align-left {
    text-align: left;
}

.text-align-center,
.align-center {
    text-align: center;
}

.text-align-right,
.align-right {
    text-align: right;
}

.text-align-justify {
    text-align: justify;
}

%basic-text {
    word-break: break-word;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #333333;
        font-family: "Lato", sans-serif;
        font-size: 18px;
        margin: 0 0 30px 0;
        line-height: 1;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    p,
    ul,
    ol {
        color: #333333;
        font-family: "Lato", sans-serif;
        font-size: 17px;
        line-height: 1.4;
        margin: 0 0 25px 0;
        letter-spacing: .2px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    figure {
        margin: 0;
        display: inline-block;
    }

    figcaption {
        text-align: center;
    }

    table {
        background-color: #ffffff;
        border-collapse: collapse;
        border-spacing: 0;
        border: solid 1px #ddeeee;
        color: #333333;
        font-family: "Lato", sans-serif;
        font-size: 13px;
        line-height: 1;
        margin: 0 0 30px 0;
        word-break: normal;

        p,
        ul,
        ol {
            font-size: 13px;
            margin: 0;
        }

        thead th {
            background-color: #ddefef;
            border: solid 1px #ddeeee;
            padding: 10px;
            text-align: left;
        }

        tbody td {
            border: solid 1px #ddeeee;
            color: #333333;
            font-weight: bold;
            padding: 10px;
        }
    }
}