@font-face {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: normal;
  src: url('/assets/fonts/Open_Sans/OpenSans-Italic.ttf') format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/Open_Sans/OpenSans-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Lato/Lato-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: normal;
  src: url('/assets/fonts/Lato/Lato-RegularItalic.ttf') format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/Lato/Lato-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: bold;
  src: url('/assets/fonts/Lato/Lato-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/HelveticaNeue/HelveticaNeue.ttc') format("truetype");
}
